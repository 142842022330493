<template>
  <section id="reporting-calendar" class="section">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="is-size-2">Calendar</h1>
        </div>
      </div>
    </div>
    <div class="level">
      <div class="level-middle">
        <div class="level-item is-size-3">
          <h2>{{ dayMonthYear(startDate) }} to {{ dayMonthYear(endDate) }}</h2>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <label for="require-notes" class="label is-small">
            <input id="require-notes" v-model="requireNotes" type="checkbox" class="is-small" />
            Require notes
          </label>
        </div>
        <div class="level-item">
          <label for="show-no" class="label is-small">
            <input id="show-no" v-model="showDeclinedEvents" type="checkbox" class="is-small" />
            Show declined events
          </label>
        </div>
        <div class="level-item">
          <label for="update-auto" class="label is-small">
            <input id="update-auto" v-model="updateParticipants" type="checkbox" class="is-small" />
            Update attendees
          </label>
        </div>

        <div class="level-item">
          <button class="button is-small" @click="loadEvents">Refresh</button>
        </div>
      </div>
    </div>

    <ReportingCalendarToolbar
      :filter-mode="filterMode"
      @set-filter="setFilterMode"
      @shift-weeks="shiftWeeks"
      @load-current-week="loadCurrentWeek"
    />

    <div v-if="googleActive" class="is-flex is-flex-direction-column">
      <ReportingEvent
        v-for="event in filteredEvents"
        :key="event.id"
        :calendar="calendar"
        :event="event"
        :transcript-id="transcriptIdByCalId[event.id]"
        :activity="activityFor(event)"
        @event-updated="updateEvent"
        @add-contact="addContact"
        @expand-event="selectedEvent = event"
      />
    </div>

    <Teleport to="#app">
      <ModalContainer :show="!!selectedEvent" @close="selectedEvent = null">
        <ReportingEvent
          :calendar="calendar"
          :event="selectedEvent"
          :transcript-id="transcriptIdByCalId[selectedEvent.id]"
          :activity="activityFor(selectedEvent)"
          @event-updated="updateEvent"
          @add-contact="addContact"
        />
        <template #controls>
          <button class="button" @click="selectedEvent = null">
            <span class="icon"><font-awesome-icon icon="times" size="lg" /></span>
          </button>
          <button class="button" @click="selectedEvent = previousEvent">
            <span class="icon"><font-awesome-icon icon="caret-up" size="lg" /></span>
          </button>
          <button class="button" @click="selectedEvent = nextEvent">
            <span class="icon"><font-awesome-icon icon="caret-down" size="lg" /></span>
          </button>
        </template>
      </ModalContainer>
    </Teleport>

    <div v-if="triageComplete" class="level">
      <div class="level-item all-done">
        <p>You're all caught up!</p>
        <p>Switch to another week or <a @click="filterMode = 'all'">view all events</a>.</p>
      </div>
    </div>
    <div v-else-if="filteredEvents.length == 0" class="level">
      <div class="level-item all-done">
        <p>No {{ filterMode }} events found for this week.</p>
        <p>
          Switch to another week or <a @click="filterMode = 'triage'">triage remaining events</a>.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "pinia"
import ModalContainer from "@/components/ModalContainer.vue"
import ReportingCalendarToolbar from "@/components/ReportingCalendarToolbar.vue"
import ReportingEvent from "@/components/ReportingEvent.vue"
import { activities, people } from "@/common/pipedrive/resources"
import { participants } from "@/common/pipedrive/activities"
import googleCal from "@/common/google/calendar"
import { googleClient } from "@/common/google/client"
import { personPictureUrl } from "@/common/people"
import { useCalendarStore } from "@/stores/calendar"
import { useGoogleStore } from "@/stores/google"
import { useUserStore } from "@/stores/user"
import { toDate, toDateString, toUTCDateString } from "@/utils/date"
import { format as dateFormat } from "@/utils/dateFormatter"
import { addWeeks, startOfWeek, endOfWeek } from "date-fns"

import { TRANSCRIPTS_BY_DATE } from "@/graphql/fireflies"

export default {
  components: {
    ModalContainer,
    ReportingCalendarToolbar,
    ReportingEvent,
  },
  props: {
    category: {
      type: String,
      default: "triage",
      required: false,
    },
    start: {
      type: String,
      default: null,
      required: false,
    },
    // override calendar displayed via query string for testing
    // ex: &calendar=joe%40heavybit.com
    calendarOverride: {
      type: String,
      default: null,
    },
  },
  setup() {
    const calendarStore = useCalendarStore()
    const googleStore = useGoogleStore()
    const userStore = useUserStore()
    return { calendarStore, googleStore, userStore }
  },
  data() {
    return {
      calendar: "primary",
      // calendar: "joe@heavybit.com", // use delegated cal in dev
      filterMode: "triage",
      showDeclinedEvents: false,
      requireNotes: true,
      events: [],
      transcripts: [],
      loading: true,
      people: {},
      startDate: new Date(),
      endDate: new Date(),
      updateParticipants: false,
      selectedEvent: null,
    }
  },
  apollo: {
    $client: "firefliesClient",
    transcripts: {
      query: TRANSCRIPTS_BY_DATE, // Not currently possible to query by cal_id, so we're querying by date
      variables() {
        return {
          fromDate: toDate(this.startDate),
          toDate: toDate(this.endDate),
        }
      },
      skip() {
        // Only fetch transcripts if we have a valid date range
        return !this.startDate || !this.endDate
      },
    },
  },
  computed: {
    ...mapState(useGoogleStore, {
      googleActive: "googleAvailable",
    }),
    activities() {
      return this.calendarStore.activities
    },
    filteredEvents() {
      let shownEvents = this.showDeclinedEvents
        ? this.events
        : this.events.filter((e) => !this.isDeclined(e))

      if (this.filterMode == "triage") return shownEvents.filter(this.needsTriage)
      if (this.filterMode == "all") return shownEvents

      // filtered by category
      return shownEvents.filter((event) => this.eventCategory(event) == this.filterMode)
      // return shownEvents.filter(e => this.showCategories.includes(this.eventCategory(e)));
    },
    nextEvent() {
      if (this.selectedEvent) {
        const index = this.filteredEvents.findIndex((e) => e.id == this.selectedEvent.id)
        // If the event is not found (i.e. index == -1), return the first event
        if (index < this.filteredEvents.length - 1) {
          return this.filteredEvents[index + 1]
        }
      }
      return null
    },
    previousEvent() {
      if (this.selectedEvent) {
        const index = this.filteredEvents.findIndex((e) => e.id == this.selectedEvent.id)
        if (index > 0) {
          return this.filteredEvents[index - 1]
        }
      }
      return null
    },
    emails() {
      const emailSet = new Set()
      this.events.forEach((event) => {
        if (!event.attendees) return
        event.attendees.forEach((attendee) => {
          if (!attendee.resource) emailSet.add(attendee.email)
        })
      })
      return [...emailSet]
    },
    triageComplete() {
      return !this.loading && this.filterMode == "triage" && !this.filteredEvents.length
    },
    transcriptIdByCalId() {
      console.log("transcriptIdbyCalId - total transcripts:", this.transcripts?.length)
      // Map transcripts by both calendar ids to transcripts' ids for easy lookup
      return this.transcripts?.reduce((acc, transcript) => {
        acc[transcript.calendar_id] = transcript.id
        acc[transcript.cal_id] = transcript.id
        return acc
      }, {})
    },
  },
  watch: {
    category(newValue) {
      if (this.filterMode == newValue) return
      this.filterMode = newValue
    },
    googleActive(newValue, oldValue) {
      // if google finishes loading or authorizing after render, load when ready
      if (newValue && !oldValue) this._loadInitialEvents()
    },
  },
  mounted() {
    if (this.calendarOverride) this.calendar = this.calendarOverride
    this.filterMode = this.category
    this._loadInitialEvents()
  },
  methods: {
    activityFor(event) {
      if (!this.activities?.length) return null
      const activityId = event?.extendedProperties?.private?.activityId
      if (!activityId) return null
      return this.activities.find((act) => act.id == activityId)
    },
    dayMonthYear(date) {
      return dateFormat(toDate(date), "dd MMM yy")
    },
    loadCurrentWeek() {
      this.loadWeek(new Date())
      this.updateQueryParams()
    },
    loadWeek(start) {
      this.startDate = startOfWeek(start, { weekStartsOn: 1 })
      this.endDate = endOfWeek(start, { weekStartsOn: 1 })
      this.loadEvents()
    },
    // timeshift by N weeks and fetch calendar data for that week
    shiftWeeks(weeks) {
      this.loading = true
      this.startDate = addWeeks(this.startDate, weeks)
      this.endDate = addWeeks(this.endDate, weeks)
      this.updateQueryParams(this.startDate)
      this.loadEvents()
    },
    setFilterMode(category) {
      this.filterMode = category
      this.updateQueryParams()
    },
    updateEvent(id, newEvent) {
      const index = this.events.findIndex((e) => e.id === newEvent.id)
      const oldEvent = this.events[index]
      Object.assign(oldEvent, { extendedProperties: newEvent.extendedProperties })
      this.events[index] = newEvent
      this.enrichAttendeesForEvent(this.events[index])
    },
    isDeclined(event) {
      const email = this.userStore.email
      const attendees = event.attendees || []
      const self = attendees.find((p) => p.email === email)
      return self && self.responseStatus === "declined"
    },
    needsTriage(event) {
      // All events without a category need triaging
      const category = this.eventCategory(event)
      if (!category) return true

      // Certain categories need activities to be triaged
      if (["portfolio", "pipeline", "networking"].includes(category)) {
        const props = event.extendedProperties?.private || {}

        // If it doesn't have an activity ID, it needs triaging
        if (!props.activityId) return true
        // If notes are required, has one been written?
        return this.requireNotes && props.note !== "true"
      }

      // Other event types don't need further triaging if categorized
      return false
    },
    eventCategory(event) {
      return this.hasCategory(event) ? event.extendedProperties.private.eventCategory : null
    },
    hasCategory(event) {
      return (
        event.extendedProperties &&
        event.extendedProperties.private &&
        event.extendedProperties.private.eventCategory
      )
    },
    loadEvents() {
      if (this.id == 0) return

      const calendarId = this.calendar
      const timeMin = toDateString(this.startDate) + "T00:00:00-08:00"
      const timeMax = toDateString(this.endDate) + "T23:59:59-08:00"

      this.$toasted.info(`Fetching ${timeMin.substring(0, 10)} to ${timeMax.substring(0, 10)}`)

      // Fetch the Pipedrive activities and GCal events for the week
      this._fetchActivities(this.startDate, this.endDate)
      this._fetchEvents(calendarId, timeMin, timeMax)
    },
    async _fetchPeople() {
      console.log("Fetching Pipedrive contacts for events")

      // Create a list of emails that don't have a person associated with them
      const emails = this.emails.filter((email) => !this.people[email]?.id)
      if (emails.length == 0) {
        // we already have all contacts, enrich immediately
        this.enrichAttendees()
        return
      }

      // Create promises to fetch the people from Pipedrive in batches of 10
      this.$toasted.info(`Fetching ${emails.length} ${emails.length == 1 ? "person" : "people"}`)

      // Given a Pipedrive person, create an array of their emails
      const personEmails = (p) => p.email.map((e) => e.value)

      people
        .byEmails(emails, (people) => {
          // this callback will be called multiple times, as each batch of people is received
          console.log(`Recieved ${people.length} Pipedrive contacts`)
          emails.forEach((email) => {
            // Loop through the persons and find the one with the matching email
            const match = (people || []).find((p) => personEmails(p).includes(email))
            this.people[email] = match || {}
          })
          this.enrichAttendees()
        })
        .catch((error) => {
          console.error("Error fetching people: ", error)
          this.$toasted.error("Error fetching people")
        })
    },
    // enrich attendees for all events
    enrichAttendees() {
      // console.log("Enriching all event attendees");
      this.events.forEach((event) => this.enrichAttendeesForEvent(event))
    },
    // Add additional metadata and profile pics to event attendees
    enrichAttendeesForEvent(event) {
      // console.log("Enriching attendees for event", event.summary);
      if (!event.attendees) return

      event.attendees.forEach((attendee) => {
        const person = this.people[attendee.email]
        if (!person?.id) return

        attendee["pipedriveId"] = person.id
        attendee["displayName"] = person.name
        if (person.org_id?.value) {
          attendee["organizationId"] = person.org_id.value
          attendee["organizationName"] = person.org_id.name
        }

        if (person.picture_id) {
          attendee["imageUrl"] = personPictureUrl(person)
        }
      })
      if (this.updateParticipants) this._updateActivityParticipants(event)
    },
    _updateActivityParticipants(event) {
      // To update the participants the event must have an activity ID, that
      // activity must exist in the store and the event must have more attendees
      // than the activity has participants

      // Check if the event is associated with an activity
      const activityId = event?.extendedProperties?.private?.activityId || -1
      if (activityId < 0) return

      // Find the activity associated with event - throw an error if not found
      const activity = this.activities.find((act) => act.id == activityId)
      if (!activity) {
        console.error(`Activity not found for event id ${event.id}`)
        this.$toasted.error(`Activity not found for ${event.subject}`)
        return
      }

      // If there is a person associated with the activity, use their ID as "Primary" person
      const props = event.extendedProperties?.private || {}
      const personId = Number(props.personId || "-1")

      // Create an array of the participants for the event in the Pipedrive API format
      const eventParticipants = participants(event.attendees, personId)
      const activityParticipants = activity.participants || []

      // If the event has more participants than the activity, update the activity with all participants
      const newParticipants = eventParticipants.length - activityParticipants.length
      if (newParticipants > 0) {
        activities.update(activityId, { participants: eventParticipants }).then(
          (response) => {
            this.calendarStore.updateActivity(response)
            this.$toasted.info(`Added ${newParticipants} participants to ${activity.subject}`)
          },
          (error) => {
            console.error("Activity update request failed: ", error.response)
            this.$toasted.error(`Error creating ${this.eventCategory}`)
          }
        )
      }
    },
    addContact(person) {
      // Create a new contact in Pipedrive & update the events
      people
        .create(person)
        .then((p) => {
          this.people[person.email] = p
          this.enrichAttendees()
        })
        .catch((error) => {
          this.$toasted.error(`Error adding contact for '${person.email}'`)
          console.error(error)
        })
    },
    updateQueryParams() {
      const startString = toDateString(this.startDate)
      const query = this.$route.query
      // don't navigate if we're already there
      if (query.start == startString && query.category == this.filterMode) return
      this.$router.replace({ query: { category: this.filterMode, start: startString } })
    },
    async _fetchActivities(startDate, endDate) {
      // Fetch all potentially related activities for the current week
      const params = {
        start_date: toUTCDateString(startDate),
        end_date: toUTCDateString(endDate),
        limit: 1000,
      }

      activities
        .filtered(params)
        .then((acts) => {
          // Pipedrive returns null for an empty set, so only set if present
          if (acts?.length) this.calendarStore.setActivities(acts)
        })
        .catch((error) => {
          console.error("Error fetching Pipedrive activities: ", error)
          this.$toasted.error("Error fetching Pipedrive activities")
        })
    },
    _loadInitialEvents() {
      if (!this.googleActive) return
      const startDate = toDate(this.start || new Date())
      this.loadWeek(startDate)
    },
    _removeAllDayEvents(events) {
      return events.filter((event) => event.start && !event.start.date)
    },
    _removeReclaimEvents(events) {
      return events.filter((event) => {
        return !event.extendedProperties?.private?.["reclaim.event.type"]
      })
    },
    async _fetchEvents(calendarId, timeMin, timeMax) {
      const client = await googleClient()
      if (!client) return // google not available

      return googleCal
        .listEvents(client, calendarId, timeMin, timeMax)
        .then((response) => {
          this.events = this._removeAllDayEvents(this._removeReclaimEvents(response.result.items))
          this.events.reverse() // put in reverse chronological order
          this._fetchPeople()
          this.loading = false
        })
        .catch((response) => {
          let errors = JSON.parse(response.body).error.errors
          console.error(JSON.parse(response.body).error)
          errors.map((e) => this.$toasted.error(e.message))
        })
    },
  },
}
</script>

<style lang="sass">
#reporting-calendar
  .tabs.is-small
    font-weight: 400
    margin-bottom: 0.8rem
    li a
      padding: 0.1rem 0.4rem 0.1rem 0.4rem
  .all-done
    padding: 2rem
    color: var(--auger-steel-gray-dark)
    flex-direction: column
</style>
