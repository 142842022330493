<template>
  <nav id="nav" role="navigation" class="navbar is-dark">
    <div class="navbar-brand">
      <router-link id="home-link" to="/" class="navbar-item">
        <img id="logo" src="@/assets/images/Heavybit-Symbol-RGB-Light.svg" />
      </router-link>
      <QuickNav
        v-if="quickNavOpen"
        @cancel="toggleQuickNav(false)"
        @submit="toggleQuickNav(false)"
      />
      <a
        role="button"
        class="navbar-burger"
        :class="{ 'is-active': burgerOpen }"
        aria-label="menu"
        aria-expanded="false"
        @click.prevent="toggleBurgerMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" :class="{ 'is-active': burgerOpen }">
      <div class="navbar-end">
        <router-link :to="{ name: 'overview' }" class="navbar-item">Overview</router-link>
        <router-link :to="{ name: 'members' }" class="navbar-item">Members</router-link>
        <router-link :to="{ name: 'companies' }" class="navbar-item">Companies</router-link>
        <router-link :to="{ name: 'financing' }" class="navbar-item">Financing</router-link>
        <router-link v-if="isPartner" :to="{ name: 'calendar' }" class="navbar-item">
          Calendar
        </router-link>
        <router-link v-if="isPartner" :to="{ name: 'funds' }" class="navbar-item">
          Funds
        </router-link>
        <div
          class="profile-menu navbar-item has-dropdown is-hoverable"
          :class="{ 'is-active': profileOpen }"
          @click.prevent="toggleProfileMenu"
        >
          <a class="navbar-link">
            <span class="icon is-medium">
              <Gravatar
                id="user-image"
                :email="userEmail"
                :size="32"
                class="navbar-item"
                default-img="mp"
              />
            </span>
          </a>
          <div class="navbar-dropdown dropdown is-right">
            <router-link :to="{ name: 'settings' }" class="dropdown-item">
              <font-awesome-icon icon="cog" pull="left" fixed-width />
              Settings
            </router-link>
            <!-- <hr class="navbar-divider" /> -->
            <a class="dropdown-item" @click.prevent="logout">
              <font-awesome-icon icon="sign-out-alt" pull="left" fixed-width />
              Sign out
            </a>
          </div>
        </div>
        <!-- <a @click.prevent="login" class="navbar-item">Login</a> -->
      </div>
    </div>
  </nav>
</template>

<script>
import Gravatar from "@/components/GravatarImage.vue"
import QuickNav from "@/components/QuickNav.vue"
import { useUserStore } from "@/stores/user"

export default {
  components: {
    Gravatar,
    QuickNav,
  },
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  data() {
    return {
      burgerOpen: false,
      profileOpen: false,
      quickNavOpen: false,
    }
  },
  computed: {
    isPartner() {
      return !!this.userStore.isPartner
    },
    userEmail() {
      return this.userStore.email || ""
    },
  },
  mounted() {
    if (!this.$mousetrap) return
    this.$mousetrap.bind("/", this.toggleQuickNav)
    this.$mousetrap.bind("esc", () => this.navigateTo("home"))
    this.$mousetrap.bind("g m", () => this.navigateTo("members"))
    this.$mousetrap.bind("g f", () => this.navigateTo("financing"))
    this.$mousetrap.bind("g o", () => this.navigateTo("overview"))
    this.$mousetrap.bind("g c", () => this.navigateTo("companies"))
    this.$mousetrap.bind("g u", () => this.navigateTo("funds"))
    this.$mousetrap.bind("b", () => {
      if (this.$route.name != "home") this.$router.go(-1)
    })

    // Add a mouseout listener to the navbar-dropdown to close the menu
    // when the mouse leaves the dropdown if it's been clicked to open.
    // This allows both hover and click to open the dropdown (and still
    // pass the ui tests for the dropdown menu).
    const dropdown = document.querySelector(".navbar-dropdown")
    if (dropdown) {
      dropdown.addEventListener("mouseout", () => {
        this.profileOpen = false
      })
    }
  },
  beforeUnmount() {
    if (!this.$mousetrap) return
    ;["/", "esc", "g m", "g f", "g o", "g c", "g u", "b"].forEach((k) => this.$mousetrap.unbind(k))
  },
  methods: {
    login() {
      this.$auth.login()
    },
    logout() {
      this.userStore.startLogout()
      this.$auth.logout()
    },
    toggleBurgerMenu() {
      this.burgerOpen = !this.burgerOpen
    },
    toggleProfileMenu() {
      this.profileOpen = !this.profileOpen
    },
    toggleQuickNav(open = true) {
      this.quickNavOpen = open && !this.quickNavOpen
    },
    navigateTo(routeName) {
      if (routeName != this.$route.name) this.$router.push({ name: routeName })
    },
  },
}
</script>

<style lang="sass" scoped>
#nav
  padding: 1.0rem
  max-width: 1000px
  margin: 0 auto
  .navbar-brand
    a
      outline: none
      background: none
      img
        max-height: inherit
        min-width: 2.5rem
  .navbar-end
    gap: 0.5rem
    a
      color: var(--auger-steel-gray-dark)
      text-transform: uppercase
      font-family: var(--auger-family-monospace)
      font-weight: 500
      font-size: 0.75rem
      outline: none
      border-radius: 4px
    a:hover
      background: var(--auger-steel-gray-light)
      color: var(--auger-steel-gray-darker)
    a:focus
      background: none
    a.router-link-exact-active
      color: var(--bulma-primary)
  .profile-menu
    .navbar-link
      user-select: none
      border-radius: 4px 4px 0 0
    .navbar-link:has(+ .navbar-dropdown:hover)
      background: white
    .navbar-dropdown
      padding: 0
      border-radius: 4px 0 4px 4px
      border: none
      .dropdown-item
        padding-top: 1.0rem
        padding-bottom: 1.0rem
        border-radius: 4px
  #user-image
    padding: 0
    max-height: inherit
    border-radius: 50%
</style>
