<template>
  <nav class="quicknav">
    <div class="dropdown is-active">
      <div class="dropdown-trigger">
        <!-- <div class="control has-icons-left"> -->
        <div class="control">
          <input
            ref="quickNav"
            v-model="filterText"
            class="input is-small"
            type="text"
            placeholder="Company name..."
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            @keyup.enter="go"
            @keydown.down="next"
            @keydown.up="next(false)"
            @keyup.esc="cancel"
            @blur="cancel"
          />
          <!-- <span class="icon is-left"><font-awesome-icon icon="search"/></span> -->
          <!-- </div> -->
        </div>
      </div>
      <div v-show="filterText.length > 0" id="dropdown-menu" class="dropdown-menu" role="menu">
        <div ref="menu" class="dropdown-content">
          <router-link
            v-for="(member, index) in filteredMembers"
            :key="index"
            :to="{ name: 'member-show', params: { id: member.id } }"
            class="dropdown-item is-size-7"
            :class="{ 'is-active': selectedIndex == index }"
          >
            {{ member.name }}
          </router-link>
          <span v-if="filteredMembers.length == 0" class="dropdown-item is-disabled">
            No results found
          </span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import gql from "graphql-tag"

export default {
  name: "QuickNav",
  emits: ["cancel", "submit"],
  apollo: {
    members: {
      query: gql`
        query members {
          members(order_by: { name: asc }) {
            id
            name
          }
        }
      `,
    },
  },
  data() {
    return {
      filterText: "",
      selectedIndex: 0,
    }
  },
  computed: {
    filteredMembers() {
      if (!this.members || this.members.length == 0) return []
      if (this.filterText.length < 1) return this.members //.slice(0, 8);
      return this.members.filter((m) =>
        m.name.toLowerCase().includes(this.filterText.toLowerCase())
      )
    },
  },
  created() {
    document.addEventListener("mousedown", () => this.$emit("cancel"), { once: true })
  },
  mounted() {
    window.setTimeout(() => this.$refs.quickNav.focus(), 100)
  },
  methods: {
    go() {
      if (this.selectedIndex < 0 || this.selectedIndex > this.filteredMembers.length - 1) return
      const member = this.filteredMembers[this.selectedIndex]
      this.filterText = ""
      this.selectedIndex = 0
      this.$router.push({ name: "member-show", params: { id: member.id } })
      this.$emit("submit")
    },
    cancel() {
      this.filterText = ""
      this.selectedIndex = 0
      this.$emit("cancel")
    },
    next(forward = true) {
      const members = this.filteredMembers.length
      this.selectedIndex = (this.selectedIndex + members + (forward ? 1 : -1)) % members
      this.ensureVisible()
    },
    ensureVisible() {
      const menu = this.$refs.menu
      const menuItem = menu.children[this.selectedIndex]
      const menuTop = menu.scrollTop
      const menuBottom = menuTop + menu.clientHeight
      const itemTop = menuItem.offsetTop
      const itemBottom = itemTop + menuItem.clientHeight
      menu.scrollTop -= itemTop < menuTop ? menuTop - itemTop : 0
      menu.scrollTop += itemBottom > menuBottom ? itemBottom - menuBottom : 0
    },
  },
}
</script>

<style lang="sass" scoped>
.icon.is-left
  animation: fadein 500ms ease-in
.quicknav
  position: absolute
  padding: 1.75rem 0.5rem
  margin-left: 3.25rem
  top: 0
  z-index: 30
  animation: fadein 100ms
  input
    border: none
    box-shadow: none
    outline: none
    min-width: 20rem
  input:not(:placeholder-shown)
    border-radius: 4px 4px 0 0
  a.dropdown-item
    text-shadow: none
    color: var(--auger-steel-gray-darker)
    outline: none
  a.is-active
    background: var(--auger-steel-gray-light)
  .dropdown-menu
    padding: 0
    border: none
    min-width: 20rem
    max-width: 20rem
    // min-width: 13.9rem
    // max-width: 13.9rem
    margin-top:-1px
  .dropdown-content
    border-radius: 0
    border: none
    max-height: 20rem
    overflow: scroll
@keyframes fadein
    from
      opacity: 0
    to
      opacity: 1
</style>
