<template>
  <table class="file-table table">
    <thead v-if="isRoot" class="root">
      <tr>
        <th>Filename</th>
        <th v-if="showMetadata" class="metadata">Created</th>
        <th v-if="showMetadata" class="metadata">Modified</th>
      </tr>
    </thead>

    <tbody :class="{ root: isRoot }">
      <template v-for="file in filteredFiles" :key="file.id">
        <FileListItem
          :file="file"
          :class="{ collapsed: isCollapsed(file) }"
          :show-tags="showTags"
          :show-metadata="showMetadata"
          @clicked="clickedFile"
          @selected="selectedFile"
          @toggle="toggleFolder(file)"
        />

        <tr v-if="isFolder(file)" :key="'folder-' + file.id" class="children">
          <td colspan="3">
            <div class="file-container">
              <FileList
                :id="file.id"
                :ref="file.id"
                :filter-text="filterText"
                :filter-type="filterType"
                :expand="expand"
                :load-on-expand="loadOnExpand"
                :show-tags="showTags"
                :show-metadata="showMetadata"
                @finished-fetching="finishedFetching"
                @clicked-file="clickedFile"
                @selected-file="selectedFile"
              />
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import FileListItem from "./FileListItem.vue"
import { googleClient } from "@/common/google/client"
import googleDrive from "@/common/google/drive"

export default {
  name: "FileList",
  components: {
    FileListItem,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
    // Show only files matching the filter text
    filterText: {
      type: String,
      required: false,
      default: "",
    },
    // Show only a particular file type
    filterType: {
      type: String,
      required: false,
      default: "",
    },
    // Show trashed files
    showTrashed: {
      type: Boolean,
      required: false,
      default: false,
    },
    expand: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadOnExpand: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTags: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMetadata: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["clickedFile", "finishedFetching", "selectedFile"],
  data() {
    return {
      files: [],
    }
  },
  computed: {
    filteredFiles() {
      const filterText = this.filterText && this.filterText.length > 2
      const filterType = this.filterType && this.filterType.length > 10

      const filterFn = (f) =>
        ((this.showTrashed || !f.trashed) && this.isFolder(f)) ||
        ((this.showTrashed || !f.trashed) &&
          (filterText ? f.name.toLowerCase().includes(this.filterText) : true) &&
          (filterType ? this.filterType.includes(f.mimeType) : true))

      let filteredFiles = this.files.filter(filterFn)

      if (filterText) {
        filteredFiles.map((f) => {
          let html = this.highlightText(f.name, this.filterText)
          f["htmlName"] = html
        })
      }
      return filteredFiles
    },
  },
  watch: {
    id(newValue) {
      this.fetchFiles(newValue)
    },
  },
  mounted() {
    if (this.isRoot || !this.loadOnExpand) this.fetchFiles(this.id)
  },
  methods: {
    toggleFolder(folder) {
      folder["isExpanded"] = !folder.isExpanded
      if (folder.isExpanded && this.loadOnExpand) {
        const childFileList = this.$refs[folder.id][0] // Child is in a v-for so refs returns an array
        childFileList.fetchFiles(folder.id)
        folder["isFetching"] = true
      }
    },
    isFolder(file) {
      return file.mimeType === "application/vnd.google-apps.folder"
    },
    isCollapsed(file) {
      return this.isFolder(file) && !file.isExpanded && this.filterText.length < 3 && !this.expand
    },
    highlightText(text, substring) {
      var index = text.toLowerCase().indexOf(substring.toLowerCase())
      if (index >= 0) {
        return (
          text.substring(0, index) +
          "<span class='highlight'>" +
          text.substring(index, index + substring.length) +
          "</span>" +
          text.substring(index + substring.length)
        )
      }
      return text
    },
    async fetchFiles(folderId) {
      if (this.id == 0) return
      // console.log(`Fetching folder: ${this.id}`);
      const client = await googleClient()
      if (!client) return // google not available

      await googleDrive
        .listFiles(client, folderId)
        .then((response) => {
          var files = response.result.files
          this.files = files.sort(googleDrive.sortFiles)
          this.$emit("finishedFetching", folderId)
        })
        .catch((response) => {
          let errors = JSON.parse(response.body).error.errors
          errors.map((e) => this.$toasted.error(`Unable to list files: ${e.message}`))
          this.$emit("finishedFetching", folderId)
        })
    },
    finishedFetching(folderId) {
      // console.log("Finished fetching:" + folderId);
      this.files.forEach((f) => {
        if (f.isFetching && f.id == folderId) f.isFetching = false
      })
    },
    clickedFile(fileId, mimeType, webViewLink) {
      // Pass click event up hierarchy
      this.$emit("clickedFile", fileId, mimeType, webViewLink)
    },
    selectedFile(fileId) {
      // Pass selection up heirarchy
      this.$emit("selectedFile", fileId)
    },
  },
}
</script>

<style lang="sass">
table.file-table
  width: 100%
  font-size: 0.9rem

  // Standard cell
  td
    border: 1px solid pink
    border: none
    padding: 0.4rem 0 0.4rem 0
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  td.filename
    width: auto
    max-width: 35rem
    padding-bottom: 0.3rem
    a:hover
      font-weight: 400
    a img
      position: relative
      top: 3px
      height: 16px
      width: 16px
      margin-right:0.4rem

  // Folder expansions/collapse widgets
  tr.folder > td.filename > a::before
    position: relative
    margin-right: 0.3rem
    top: -1px
    font-family: var(--auger-family-monospace)
    color: gray
    content:'-'
  tr.folder.collapsed > td.filename > a::before
    content:'+'

  // Folder loading widgets
  tr.folder > td.filename > a
    span:after
      overflow: hidden
      display: inline-block
      vertical-align: bottom
      animation: ellipsis steps(4,end) 2s infinite
      content: "\2026" // ascii code for the ellipsis character
      width: 0px
    span.is-loaded:after
      transition: opacity 500ms ease-out
      opacity: 0
      animation-iteration-count: 1

  // Row borders & vertical padding
  tr.folder.collapsed,
  tr.children:not(:last-child),
  tbody.root > tr:last-child
    border-bottom: 1px solid #DBDADB
  tr.folder:nth-last-child(2)
    border-bottom: none
  tr.collapsed + .children
    border-bottom: none
  tr + .children .file-container
    max-height: 1000px
    transition: max-height 0.2s ease-in
  tr.collapsed + .children .file-container
    max-height: 0
    transition: max-height 0s linear
  tr.children > td
    padding: 0

  // Horizontal indentation
  tr.children > td
    padding-left: 1.3rem
  tr > td.filename
    padding-left: 0.9rem
  tr.folder > td.filename
    padding-left: 0
  tbody.root > tr > td.filename
    padding-left: 1rem
  thead.root > tr > th,
  tbody.root > tr.folder > td
    padding-left: 0.1rem

  td.metadata,
  th.metadata
    padding-right: 0
    width: 9.5rem
    text-align: right
  td.metadata
    color: darkgray

span.highlight
  background: lightyellow
  color: black
  font-weight: 400
  padding-top: 0.25rem
  padding-bottom: 0.25rem
div.tags.are-small
  margin-left: 1rem
  position: relative
  top: 1px
  > a,
  > span
    font-size: 8pt
    border-radius: 3px
    border:1px solid lightgray

@keyframes ellipsis
  to
    width: 1rem
</style>
